

import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Generate from '../components/Generate';
import ResultImage from '../components/ResultImage';
import '../pages/Home.css';

function Home() {
  const [resultImage, setResultImage] = useState('');
 

  const handleGenerate = (generatedImage) => {
    setResultImage(generatedImage);
  };
  


  return (
    <div className="generator-container"> {/* Changed to div and fixed className */}
    <div className="generator-header"> {/* Changed to div and fixed className */}
      <h1 className="generator-title">AI Anime Generator</h1> {/* Fixed className */}
      <p className="generator-description">Online AI Anime Art Generator for free. Choose from 50+ Distinct Anime AI filters.</p> {/* Fixed className */}
    </div>
 
    <Row>
      <Col md="5" className="generate-column"  style={{ backgroundColor: '#26263E', padding: '0px', borderRadius: '10px'}}>
        <Generate onGenerate={handleGenerate} />
      </Col>
      <Col md="5">
        <ResultImage resultImage={resultImage} />
      </Col>
    </Row>
  
  </div>
  );
}

export default Home;