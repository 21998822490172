import logo from './logo.svg';
import React from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import { AuthProvider } from "../src/Auth"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/Home';
import ForgotPassword from "../src/components/ForgotPassword";
import Header from './components/Header';
import SubscriptionPage from './pages/checkout/SubscriptionPage';
import RefundPolicy from './pages/refund-policy/RefundPolicy';

import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='subscribe' element={<SubscriptionPage/>}/>
          <Route path="/refund-policy" element={<RefundPolicy />} />

        </Routes>
        <ToastContainer
        />
      </AuthProvider>
    </Router>
  );
}

export default App;