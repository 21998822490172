import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './RefundPolicy.css';

function RefundPolicy() {
  return (
    <div className="refund-policy-container">
      <div className="refund-policy-header">
        <h1 className="refund-policy-title">Refund Policy</h1>
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <div className="refund-policy-content">
              <p>At animex.ai, we strive to provide the best possible experience for our users. We offer a 14-day refund policy for our subscription services, subject to the following terms and conditions:</p>
              
              <h2>Refund Eligibility</h2>
              <ol>
                <li>Refund requests must be made within 14 days of the initial subscription purchase.</li>
                <li>Users are eligible for a refund only if they have generated fewer than 20 images using our service.</li>
                <li>We do not offer partial refunds. If you are eligible for a refund, you will receive a full refund of your subscription fee.</li>
              </ol>

              <h2>Refund Process</h2>
              <ol>
                <li>To request a refund, please send an email from your registered email address to <a href="mailto:support@limbicapps.com">support@limbicapps.com</a>.</li>
                <li>In your email, please include your account username and the reason for your refund request.</li>
                <li>Our team will review your refund request within two weeks of receipt.</li>
                <li>If your refund is approved, please allow up to 10 business days for the refund to appear on your statement.</li>
              </ol>

              <h2>Important Notes</h2>
              <ul>
                <li>Refund requests are subject to review and may be denied if the terms of this policy are not met.</li>
                <li>We reserve the right to deny refunds to users who abuse this policy or our service.</li>
                <li>By requesting a refund, you agree that your subscription will be cancelled, and you will lose access to our premium features.</li>
              </ul>

              <p>For any questions regarding our refund policy, please contact us at <a href="mailto:support@limbicapps.com">support@limbicapps.com</a>.</p>

              <p>We appreciate your understanding and thank you for choosing animex.ai for your anime art generation needs.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RefundPolicy;
