

import React, {useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {app,auth,signInWithGoogle} from '../../src/firebase';  // adjust this import based on your directory structure
import {   createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import {toast} from 'react-toastify'
import './SignIn.css';


const SignInPopup = ({ show, handleClose }) => {
 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
    }
  });
 
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };
  const validatePassword = (password) => {
    const passwordRules = /(?=.*\d)(?=.*[a-zA-Z]).{6,}/;
    setPasswordValid(passwordRules.test(password));
  };
  const handleMouseOver = () => {
    setShowTooltip(true);
  };
  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  const handleSubmit = async () => {
    // Logic for handling form submission
    console.log("Email:", email);
    console.log("Password:", password);
    // Add your sign-in logic here

    try{
      const response = await  createUserWithEmailAndPassword(auth,email,password);
      const user = response.user;
      if (user){
toast.success("Welcome")
      }

    }
    catch(error){
      toast.success(error.errorMessage)
    }
   
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Body className="signin-modal-body">
        <div className="signin-container">
          <div className="signin-left">
            <h2>Hello! Just one more step...</h2>
            <p>Sign up is needed for image creation</p>
            <Button variant="light" className="google-button" onClick={signInWithGoogle}>
              <img src="google.png" 
              alt="Google Icon" 
               width={30} 
          height={30} 
          style={{ marginRight: '5px' }} /> Google ile oturum açın
            </Button>
            <div className="divider">
              <span>OR</span>
            </div>
            <Form>
      <Form.Group controlId="formEmail" className="input-group">
        <Form.Control
          type="email"
          placeholder="Email*"
          className="signin-input"
          value={email}
          onChange={handleEmailChange}
        />
      </Form.Group>
      <Form.Group controlId="formPassword" className="input-group" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <Form.Control
          type="password"
          placeholder="Password*"
          className={`signin-input ${passwordValid ? '' : 'invalid-password'}`}
          value={password}
          onChange={handlePasswordChange}
        />
         <span className="tooltip-text" style={{ visibility: showTooltip ? 'visible' : 'hidden' }}>
                  Password must be at least 6 characters long, contain letters and numbers.
                </span>
      </Form.Group>
      {
                !passwordValid && (
                  <div className="password-rules">
                    Password must be at least 6 characters long, contain letters and numbers.
                  </div>
                )
              }
       <Link to="/forgot-password" className="forgot-password">Forget password?</Link>
      <Button variant="primary" className="signin-button" onClick={handleSubmit}>
        SIGN IN
      </Button>
      <Form.Group className="checkbox-group">
        <Form.Check 
          type="checkbox" 
          label="I agree to receive newsletter from AnimeX.AI" 
          id="newsletter" 
        />
      </Form.Group>
      <p>By signing up, I agree to AnimeX.AI's Terms of Use & Privacy Policy</p>
    </Form>
          </div>
          <div className="signin-right">
          <div className="text-container">
            <h3>Benefits of Logging In</h3>
            <ul>
              <li>Image Creation Access</li>
              <li>30 Bonus Credits For Sign-up</li>
              <li>Access to community gallery</li>
              <li>200 images free storage in history</li>
            </ul>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SignInPopup;
