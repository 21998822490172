import React, { useState } from 'react';
import { Navbar, Nav, Container, NavDropdown, Row, Button } from 'react-bootstrap';

import { BiLogIn } from 'react-icons/bi';
import styles from './Header.css';
import SignInPopup from './SignIn'; // Ensure this path is correct

function Header() {
  const handleShowSignIn = () => setShowSignIn(true);
  const handleCloseSignIn = () => setShowSignIn(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const products = [
    { name: "AI Tattoo Generator", link: "https://apps.apple.com/us/app/ai-tattoo-generator-stencil/id6477332274?platform=iphone", icon: "tattoo.png" },
    { name: "Art-X: AI Art Generator", link: "https://apps.apple.com/us/app/art-x-a-i-art-generator-aiart/id1644315225", icon: "artx.jpg" },
    { name: "AI Essay Writer", link: "https://apps.apple.com/us/app/llama-ai-essay-writer-typer/id1669558411", icon: "gpt.jpg" },
    { name: "Harmony: Relationship Coach", link: "https://apps.apple.com/us/app/harmony-relationship-coach-ai/id6499267412", icon: "harmony.jpg" },
    { name: "NostalgiaAI: Restore Old Photos", link: "https://apps.apple.com/us/app/nostalgiaai-restore-old-photos/id1510101532?platform=iphone", icon: "colorizer.png" },
    { name: "AI Photo Generator", link: "https://apps.apple.com/us/app/ai-generated-images-aiart-ai/id6469149383", icon: "bonfire.jpg" },
    { name: "PDF Scanner", link: "https://apps.apple.com/us/app/pdf-scanner-free-jpg-to-pdf/id1513394299", icon: "pdf.png" },
  ];

  return (
    <>
      <Navbar expand="lg" className={styles.navbar}>
        <Container>
        <Navbar.Brand href="#home" className={styles.brand}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img
      className={styles.logo}
      src="/1024.png"
      width={60}
      height={60}
      alt="AnimeX AI Icon"
    />
  <span 
  className={styles.brandName}
  style={{ color: "#ffff", paddingLeft: '1rem', fontFamily: "'New Amsterdam', sans-serif", fontSize: '24px', fontWeight: 'bold'}}
>
  ANIMEX AI
</span>
  </div>
</Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" className={`custom-toggler ${styles.customToggler}`} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={styles.navLinks}>
              <NavDropdown title="Products" id="basic-nav-dropdown" className="white-nav-dropdown">
                {products.map((product, index) => (
                  <NavDropdown.Item
                    key={index}
                    href={product.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={product.icon}
                        alt={product.name}
                        width={30}
                        height={30}
                        style={{ marginRight: '8px' }}
                        // color= "#ffff"
                      />
                      <span>{product.name}</span>
                    </div>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <Nav.Link href="https://discord.gg/dtqCVCzR" target="_blank" rel="noopener noreferrer" className={styles.navLink}>
                <img src={"/discord.png"} alt="" width={30} height={30} className="me-2" />
              </Nav.Link>
              <Button
                style={{ backgroundColor: '#8b3dff', borderColor: '#6D28D9', color: '#fff' }}
                onClick={handleShowSignIn}
              >
                Sign In
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <SignInPopup show={showSignIn} handleClose={handleCloseSignIn} />
    </>
  );
}

export default Header;