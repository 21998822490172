// import React from 'react';
// import { Card } from 'react-bootstrap';

// const ResultImage = () => (
//   <Card bg="light" text="dark" className="p-4">
//     <Card.Body>
//       <Card.Title>Result Image</Card.Title>
//       <Card.Text>
//         This is where the generated image will be displayed.
//       </Card.Text>
//       <Card.Img variant="top" src="path-to-your-placeholder-image.jpg" />
//     </Card.Body>
//   </Card>
// );

// export default ResultImage;
import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';

const ResultImage = ({ resultImage }) => {
  const [image, setImage] = useState('resultImage.jpg');

  useEffect(() => {
    if (resultImage) {
      setImage(resultImage);
    }
  }, [resultImage]);

  return (
    <Card style={{ backgroundColor: '#26263E' }} text="dark" className="p-4" >
    <Card.Body>
      <Card.Title></Card.Title>
      <Image src={image} fluid />
    </Card.Body>
  </Card>
  );
};

export default ResultImage;