import './Generate.css';
import React, { useState } from 'react';
import { createArt } from '../../src/firebase';
import { toast } from 'react-toastify';

const Generate = ({ onGenerate })  => {
  // Model mapping based on the Swift code
  const modelMapping = {
    "3D": "realcartoonanime_10",
    "2X Detail": "anything_4_0",
    "Any V5": "anything_5_0",
    "HD": "yesmix_4",
    "Realistic": "epic_diffusion_1_1",
    "Classic": "dh_classicanime"
  };

  const [selectedModel, setSelectedModel] = useState('2X Detail');
  const [prompt, setPrompt] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState('Square');
  const [selectedStyle, setSelectedStyle] = useState('None');

  // Update options to match the keys in modelMapping
  const options = Object.keys(modelMapping);
  
  const aspectRatios = ['Square', 'Portrait', 'Landscape'];
  const styles = [
    { name: 'None', icon: '' },
    { name: 'animeXL', icon: '' },
    { name: 'Chibi', icon: '' },
    { name: 'colorbook', icon: '' },
    { name: 'comics', icon: '' },
    { name: 'Ghibli', icon: '' },
    { name: 'giantess', icon: '' },
    { name: 'hipoly', icon: '' },
    { name: 'Isekai', icon: '' },
    { name: 'Jojo', icon: '' },
    { name: 'journey', icon: '' },
    { name: 'Kawaii', icon: '' },
    { name: 'lineart', icon: '' },
    { name: 'mecha', icon: '' },
    { name: 'musume', icon: '' },
    { name: 'Nendoroid', icon: '' },
    { name: 'sailormoon', icon: '' },
    { name: 'samyang', icon: '' },
    { name: 'SDXL', icon: '' },
    { name: 'Seinen', icon: '' },
    { name: 'Shinkai', icon: '' },
    { name: 'Shonen', icon: '' },
    { name: 'tattoo', icon: '' },
    { name: 'Vintage', icon: '' }
  ];

  const generateArt = async () => {
    setLoading(true);
    try {
      // Use the modelMapping to get the correct model_id
  
      const model_id = modelMapping[selectedModel];

      const result = await createArt({ 
        prompt: prompt, 
        imageRatio: selectedAspectRatio,
        model_id: model_id
      });
      if (result.data.error) {
        // Handle the NSFW error or any other error returned by the function
        showMobileToast(result.data.error);

      } else {
        console.log('response:', result);
        // setImage(result.data);

        onGenerate(`data:image/png;base64,${result.data}`); // Pass the generated image data to the parent component
      }

    } catch (error) {
      console.error('Error generating art:', error);
      showMobileToast('error', 'An error occurred while generating art. Please try again.');

    } finally {
      setLoading(false);
    }
  };

  const showMobileToast = ( message) => {
    // toast(message);
   
    toast.error(message, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        fontSize: '14px',
        maxWidth: '90vw',
        width: 'auto',
        textAlign: 'center',
      },
    });
  };

  return (
    <div className="app">
      <div className="top-controls">
        {options.map((option) => (
          <button
            key={option}
            className={`control-button ${selectedModel === option ? 'selected' : ''}`}
            onClick={() => setSelectedModel(option)}
          >
            {option}
          </button>
        ))}
      </div>
     
      <textarea
        className="prompt-input"
        placeholder="Write Your Prompt"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      {aspectRatios.map((ratio) => (
        <button
          key={ratio}
          className={`control-button-ratio ${selectedAspectRatio === ratio ? 'selected' : ''}`}
          onClick={() => setSelectedAspectRatio(ratio)}
        >
          {ratio}
        </button>
      ))}
      <div className="models-grid">
        {styles.map((style) => (
          <div
            key={style.name}
            className={`model-item ${selectedStyle === style.name ? 'selected' : ''}`}
            onClick={() => setSelectedStyle(style.name)}
          >
            {style.icon && <span className="crown-icon">{style.icon}</span>}
            <img src={`/images/styles/${style.name}.jpeg`} alt={style.name} />
            <span>{style.name}</span>
          </div>
        ))}
      </div>
      
      <button className="create-button" onClick={generateArt} disabled={loading}>
        <span>🎬</span> {loading ? 'Generating...' : 'Generate Art'}
      </button>
      {loading && <div>Loading...</div>}
      {/* {image && (
        <div>
          <img src={`data:image/png;base64,${image}`} alt="Generated Art" style={{ marginTop: '20px' }} />
        </div>
      )} */}
    </div>
  );
};

export default Generate;