import React, { useEffect } from 'react';
import './SubscriptionPage.css';

const SubscriptionPage = () => {
  useEffect(() => {
    // Load the LemonSqueezy script
    const script = document.createElement('script');
    script.src = 'https://assets.lemonsqueezy.com/lemon.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="subscription-container">
      <div className="subscription-card">
        <h1 className="card-title">Anime X Subscription</h1>
        <p className="card-description">Get unlimited access to all your favorite anime!</p>
        <div className="price">
          <span className="amount">$9.99</span>
          <span className="period">/month</span>
        </div>
        <ul className="features">
          <li>✅ Unlimited streaming</li>
          <li>✅ Ad-free experience</li>
          <li>✅ New releases every week</li>
          <li>✅ Cancel anytime</li>
        </ul>
        <a href="https://limbicapp.lemonsqueezy.com/buy/405c6be9-7c6b-444f-b154-7d7dc5f56fd0?embed=1" class="subscribe-button lemonsqueezy-button">Subscribe</a><script src="https://assets.lemonsqueezy.com/lemon.js" defer></script>
      </div>
    </div>
  );
};

export default SubscriptionPage;